import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({
  imageInfo, style, imgStyle, cover,
}) => {
  const placeHolderStyle = {};
  const imageStyle = cover
    ? {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      ...style,
      ...placeHolderStyle,
    }
    : {
      width: '100%',
      height: 'auto',
      ...style,
      ...placeHolderStyle,

      // maxWidth: 500,
    };

  const {
    title = '', fluid, fixed, image,
  } = imageInfo;

  if (!!image && !!image.fluid) {
    return (
      <Img style={imageStyle} imgStyle={imgStyle} {...placeHolderStyle} fluid={image} alt={title} />
    );
  }

  if (fluid) {
    return (
      <Img
        style={imageStyle}
        imgStyle={imgStyle}
        placeHolderStyle={placeHolderStyle}
        fluid={fluid}
        alt={title}
      />
    );
  }
  if (fixed) {
    return (
      <Img
        style={imageStyle}
        imgStyle={imgStyle}
        placeHolderStyle={placeHolderStyle}
        fixed={fixed}
        alt={title}
      />
    );
  }
  if (!!image && typeof image === 'string') return <img style={imageStyle} src={image} alt={title} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.object,
    // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
